import { Controller } from "stimulus"
import { debounce } from "throttle-debounce"
import Rails from "@rails/ujs"

export default class extends Controller {
    static targets = ["select", "input"]

    connect() {
        this.replaceTargetId = this.element.dataset.filterTarget
        this.components = this.element.getElementsByClassName("filter_component")
        this.inputs = this.inputTargets
        this.selects = this.selectTargets

        // restore filter params from storage
        this.loadFilter()

        // auto submit deaktiviert am 13.02.25, weil der Zurück-Button vom Browser damit nicht mehr richtig funktioniert hat
        // => wenn man gefiltert hat und dann z.B. auf Bearbeiten klickt konnte man nicht mehr zurück navigieren, es hat sich
        // nur die Adresszeile geändert

        // add an input listener on every input target
        // for (var item of this.inputs) {
        //     item.addEventListener("input", debounce(250, false, this.inputChangedCallback.bind(this)))
        // }
    }

    // sets the selected filter method (equals, contains...)
    updateInputName(e) {
        // update input name depending on filter method
        // => not necessary for collections (dropdowns), because there it is always "_eq"
        if (e.target.dataset.filterType === "collection") {
            // do nothing for collections
        } else {
            // get the whole filter_component of the element that triggered the event
            var component = e.target.closest(".filter_component")
            // get the filter_input of this filter_component
            var input = Array.from(component.children).filter(x => x.className == "filter_input")[0]
            if (input !== undefined) {
                console.log("updateInputName()", e.target.value)
                // update the input name according to the value of the selected item
                input.name = `q[${e.target.value}]`
            }
        }

        // trigger the input changed callback so a query will be sent when the type of filter changes
        this.filter()
    }

    filter() {
        console.log("filter()")
        // get the current url pathname
        const urlPathname = window.location.pathname
        // replace the title query part of the url with the input from the search field
        const queryString = this.buildQueryString()

        // persist filter options in sessionStorage
        this.persistFilter()

        // update the address bar
        window.history.pushState("", "", `${urlPathname}?${queryString}`)

        // run a get with the input from the search field and replace parts of the site without reloading it, so we do not lose focus on search field
        Rails.ajax({
            type: "GET",
            url: `${urlPathname}?${queryString}`,
            success: (_data, _status, xhr) => {
                // we only want to replace the innerHTML of the div with id article_table, so we need to parse the response and only take that element
                var parser = new DOMParser()
                var doc = parser.parseFromString(xhr.responseText, "text/html")
                var elem = doc.getElementById(this.replaceTargetId)
                // replace article_table with the new content
                document.getElementById(this.replaceTargetId).innerHTML = elem.innerHTML
            }
        })
    }

    // action to be called on title input change
    inputChangedCallback() {
        this.filter()
    }

    // replaces the title query part of the url with searchString, but keeps the other parameters as-is
    buildQueryString() {
        // get the current url search params
        const oldUrlParams = new URLSearchParams(window.location.search)
        // instead of replacing search params, create new params and copy the parts we want to keep (sort-query)
        const newUrlParams = new URLSearchParams()
        if (oldUrlParams.has('q[s]')) {
            newUrlParams.set('q[s]', oldUrlParams.get('q[s]'))
        }

        // set search params for each input
        for (var item of this.inputs) {
            if (item.value) {
                newUrlParams.set(item.name, item.value)
            }
        }

        return newUrlParams.toString()
    }

    // persists the values of selects and inputs to the sessionstorage
    persistFilter() {
        for (var item of this.selects) {
            sessionStorage.setItem(`${item.id}_value`, item.value)
        }

        for (var item of this.inputs) {
            sessionStorage.setItem(`${item.id}_name`, item.name)
            sessionStorage.setItem(`${item.id}_value`, item.value)
        }
    }

    // loads values of selects and inputs from sessionstorage and inserts them to filter
    loadFilter() {
        for (var item of this.selects) {
            let stored = sessionStorage.getItem(`${item.id}_value`)
            if (stored) {
                item.value = stored
            }
        }
        for (var item of this.inputs) {
            let stored = sessionStorage.getItem(`${item.id}_name`)
            if (stored) {
                item.name = stored
            }
            stored = sessionStorage.getItem(`${item.id}_value`)
            if (stored) {
                item.value = stored
            }
        }
    }

    // sets all inputs to empty text
    removeFilter() {
        for (var item of this.inputs) {
            item.value = ""
        }
        this.filter()
    }

}
